
import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute, NavigationStart } from '@angular/router';
import {  AuthService } from '../../services/auth.service'; 
import {  EmailsService } from '../../services/emails.service'; 
import {  SocketService } from '../../services/socket.service'; 

declare var $: any;
@Component({
  selector: 'app-layout-client',
  templateUrl: './layout-client.component.html',
  styleUrls: ['./layout-client.component.css']
})
export class LayoutClientComponent implements OnInit {

  count_vue_submittion:{
      count:number
  }

constructor(
    private route: ActivatedRoute,
    private router: Router,
    public authenticationService: AuthService,
    public emailsService:EmailsService,
    public socketService:SocketService,
    
){
  
  router.events.forEach((event) => {
    if(event instanceof NavigationStart) {
      if(event.url=="/client/response"){
        this.count_vue_submittion.count=0
      }
    }
  });
}


  logout() {
    this.authenticationService.logout();
    this.router.navigate(['']);
  }


  ngOnInit(): void {
    

    this.emailsService.count_vue_submittion(this.authenticationService.currentUserValue._id).subscribe(res => {
     this.count_vue_submittion=res
    });

   this.socketService.get_new_submittion().subscribe((object:any) => {
     
        if(object.user_id==this.authenticationService.currentUserValue._id){
          this.count_vue_submittion.count+=object.count
        }
      });


      var windowsize = $(window).width();

      $("#wrapper").toggleClass("toggled");
      $("#menu-toggle").click(function(e) {
        e.preventDefault();
        $("#wrapper").toggleClass("toggled");
      });

      if (windowsize <= 768) {

        $(".list-group-item").click(function(e) {
          e.preventDefault();
          $("#wrapper").toggleClass("toggled");
        });
    

      }

  

    

  }



}
