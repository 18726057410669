import { Injectable,Inject,PLATFORM_ID} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../models/user';
import { LOCAL_STORAGE } from '@ng-toolkit/universal';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  public  basUrl:String;

  constructor(@Inject(LOCAL_STORAGE) private localStorage: any,@Inject(PLATFORM_ID) private platformId: Object,private http: HttpClient) {

        this.basUrl='https://api-formulaire-dentitek.virussantecommunication.ca/users';

        if (isPlatformBrowser(this.platformId)) {
          this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        }else{
          this.currentUserSubject = new BehaviorSubject<User>({'_id':'','name':'','email':'','role':'','token':''});
        }
        
        this.currentUser = this.currentUserSubject.asObservable();
  
    }

  public get currentUserValue(): User {
      return this.currentUserSubject.value;
  }

  login(email: string, password: string) {

    return this.http.post<any>(this.basUrl+`/login`, { email, password })
        .pipe(map(user => {

            if (user && user.token) {
              if (isPlatformBrowser(this.platformId)) {
                localStorage.setItem('currentUser', JSON.stringify(user));
              }
                this.currentUserSubject.next(user);
            }else{
              return null
            }

            return user;
        }));
}

forget_password(email: string) {
  return this.http.post<any>(this.basUrl+`/forget_password`, { email })
}

reset_password(password: string,id: string,token: string) {
    return this.http.post<any>(this.basUrl+`/reset_password`, { password,id,token })
}

createUser(user: User) {
  return this.http.post<any>(this.basUrl+`/register`, user)
  .pipe(map(user => {
      return user;
  }));
}

logout() {
  if (isPlatformBrowser(this.platformId)) {
    localStorage.removeItem('currentUser');
  }
    this.currentUserSubject.next(null);
}

}