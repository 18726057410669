import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';
import * as io from 'socket.io-client'

@Injectable({
  providedIn: 'root'
})
export class SocketService {

  socket:any;

  constructor(private http: HttpClient) {

  this.socket= io('https://api-formulaire-dentitek.virussantecommunication.ca')
  }

  public new_submittion(user_id) {
    this.socket.emit('new_submittion', user_id);
  }

  public get_new_submittion = () => {
    return new Observable((observer) => {
            this.socket.on("new_submittion_respond", (object) => {
                observer.next(object);
            });
    });
}

}
