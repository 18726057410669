import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EmailsService {

  public  basUrl: String;

  constructor(private http: HttpClient) {
    this.basUrl = 'https://api-formulaire-dentitek.virussantecommunication.ca/emails';
  }

  all(user_id: String,page:number,search:String) {
    return this.http.get(this.basUrl+`/all?user_id=${user_id}&page=${page}&sortby=all&search=${search}`);
  }

  Emails_by_company_and_range(user_id: String, by: String,form_type: String) {
    return this.http.get(this.basUrl + `/Emails_by_company_and_range?user_id=${user_id}&by=${by}&form_type=${form_type}`);
  }

  Emails_by_company_and_range_soumis(user_id: String, by: String,form_type: String) {
    return this.http.get(this.basUrl + `/Emails_by_company_and_range_soumis?user_id=${user_id}&by=${by}&form_type=${form_type}`);
  }
  
  add(data: any) {
    return this.http.post<any>(this.basUrl+`/add`, data);
  }

  send_mail_with_form(data: any) {
    return this.http.post<any>(this.basUrl+`/send_mail_with_form`, data);
  }

  update(form_key:String,response_patient:any,validate:String) {
    return this.http.post<any>(this.basUrl+`/update/${form_key}/${validate}`, response_patient);
  }

  update_vue_submittion(user_id: String) {
    return this.http.post<any>(this.basUrl+`/update_vue_submittion/${user_id}`,null);
  }

  count_vue_submittion(user_id: String) {
    return this.http.get<any>(this.basUrl+`/count_vue_submittion/${user_id}`);
  }

  find(form_key:String) {
    return this.http.get(this.basUrl+`/find/${form_key}`);
  }

  remove(id:String) {
      return this.http.delete(this.basUrl+`/remove/${id}`);
  }

}
