import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import {  AuthService } from '../../services/auth.service'; 

declare var $: any;
@Component({
  selector: 'app-layout-admin',
  templateUrl: './layout-admin.component.html',
  styleUrls: ['./layout-admin.component.css']
})
export class LayoutAdminComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public authenticationService: AuthService
) {



}


  logout() {
    this.authenticationService.logout();
    this.router.navigate(['']);
  }


  ngOnInit(): void {
    var windowsize = $(window).width();

    $("#wrapper").toggleClass("toggled");
    $("#menu-toggle").click(function(e) {
      e.preventDefault();
      $("#wrapper").toggleClass("toggled");
    });

    if (windowsize <= 768) {

      $(".list-group-item").click(function(e) {
        e.preventDefault();
        $("#wrapper").toggleClass("toggled");
      });
  

    }

  }

}
