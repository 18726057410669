
  <div class="d-flex" id="wrapper">
    <div class="bg-light border-right" id="sidebar-wrapper">
        <div class="sidebar-heading"><img src="https://virussantecommunication.ca/wp-content/themes/virus_bootstrap/img/homepage/xlogo_03.png.pagespeed.ic.1UoAef5KWO.webp"></div>
        <div class="list-group ">
          <a routerLinkActive="active" [routerLink]="['/client/dashboard']"  class="list-group-item " style="border-top:none;"><i class="fa fa-dashboard "></i> Tableau de bord</a>
          <a routerLinkActive="active" [routerLink]="['/client/forms']"  class="list-group-item "><i class="fa fa-folder "></i> Formulaires</a>
          <a routerLinkActive="active" [routerLink]="['/client/response']" class="list-group-item "><i class="fa fa-envelope-o"></i> Mails envoyés <span *ngIf="count_vue_submittion&&count_vue_submittion.count>0" class="badge badge-pill badge-danger" title="{{count_vue_submittion.count}} Nouveaux formulaires réçu" style="margin-left:10px"> {{count_vue_submittion.count}}</span></a>
          <a style="cursor: pointer;" (click)="logout()" class="list-group-item "><i class="fa fa-sign-out"></i> Déconnexion</a>
        </div>
      </div>

    <div id="page-content-wrapper">
      
      <nav class="navbar navbar-expand-lg navbar-light bg-light border-bottom  justify-content-between">
        
        <a class="navbar-brand" [routerLink]="['/client/dashboard']" ><img src="https://virussantecommunication.ca/wp-content/themes/virus_bootstrap/img/homepage/xlogo_03.png.pagespeed.ic.1UoAef5KWO.webp"></a>
      
        <button class="navbar-toggler button-menu" id="menu-toggle"><span class="navbar-toggler-icon"></span></button>
      
        
   
            <ul *ngIf="authenticationService.currentUserValue" class="navbar-nav">

              <li class="nav-item dropdown">
                <a class="nav-link " href="#" >
                
                </a>
              </li>

              <li class="nav-item dropdown menu-logout  ">
                <a class="nav-link dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown">
                  {{authenticationService.currentUserValue.name}}
                </a>
                <div class="dropdown-menu">
                  <a class="dropdown-item" routerLinkActive="active" [routerLink]="['/client/profile']" >Profil</a>
                  <a class="dropdown-item" (click)="logout()">Déconnexion</a>
                </div>
              </li>
            </ul>

      </nav>
      <div class="container-fluid content" style="background-color:  #fafafa !important;min-height: 100vh;">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
 