import { Component, OnInit, Inject,PLATFORM_ID } from '@angular/core';
import { LOCAL_STORAGE , WINDOW} from '@ng-toolkit/universal';
import { isPlatformBrowser } from '@angular/common'; 

@Component({
  selector: 'app-switcher',
  templateUrl: './switcher.component.html',
  styleUrls: ['./switcher.component.scss']
})
export class SwitcherComponent implements OnInit {

  
  currentLang:String='fr'

  constructor(@Inject(PLATFORM_ID) private platformId: Object) { 
    if (isPlatformBrowser(this.platformId)) {
    this.currentLang=localStorage.getItem('Language') ? localStorage.getItem('Language') : 'fr'
    }
  }

  ngOnInit(): void {
  
  }
  
  changeLanguage(lang){
    if (isPlatformBrowser(this.platformId)) {
    localStorage.setItem("Language",lang)
    location.reload();
    }
  }

}
