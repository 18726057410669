import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutClientComponent } from './layouts/layout-client/layout-client.component';
import { LayoutAdminComponent } from './layouts/layout-admin/layout-admin.component';
import { AuthGuard } from './guards/auth.guard';
import { IsAdminGuard } from './guards/is-admin.guard';
import { IsClientGuard } from './guards/is-client.guard';




const routes: Routes = [
  
  {
    component: LayoutClientComponent,
    path: 'client',
    canActivate: [AuthGuard,IsClientGuard],
    loadChildren: () => import('./modules/application/client/client.module')
      .then(mod => mod.ClientModule)
  },
  {
    path: 'admin',
    component: LayoutAdminComponent,
    canActivate: [AuthGuard,IsAdminGuard],
    loadChildren: () => import('./modules/application/admin/admin.module')
      .then(mod => mod.AdminModule)
  },
  {
    path: 'forms',
    loadChildren: () => import('./modules/general/staticforms/staticforms.module')
      .then(mod => mod.StaticformsModule)
  },
  {
    path: '',
    loadChildren: () => import('./modules/general/signin/signin.module')
      .then(mod => mod.SigninModule)
  },
  

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
