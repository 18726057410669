import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedTranslationRoutingModule } from './shared-translation-routing.module';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient,HttpClientModule } from '@angular/common/http';
import { SwitcherComponent } from './switcher/switcher.component';

// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

function getLanguage() {
  return localStorage.getItem('Language') ? localStorage.getItem('Language') : 'fr'
}

@NgModule({
  declarations: [SwitcherComponent],
  imports: [
    CommonModule,
    SharedTranslationRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: getLanguage(),
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    })
  ],
  exports:[ CommonModule,TranslateModule,SwitcherComponent ]
})
export class SharedTranslationModule { }
